import React, { useRef } from "react";
import styled from "styled-components";

import { PageTopContentCreators } from "src/components/content-creators-landing-page/PageTopContentCreators";
import Header from "src/components/header";
import HelmetWrapper from "src/components/helmet-wrapper";
import { breadCrumbSchema } from "src/components/html-schema-snippets";
import Layout from "src/components/layout";
import CaseStudyPanel from "src/components/startups-landing-page/CaseStudyPanel";
import BgDubbiApp from "src/assets/images/content-creators/dubbii_meta_image.jpg";
import BgCreator from "src/assets/images/content-creators/pexels-blue-bird-7243156.jpg";
import BgBeachGuy from "src/assets/images/content-creators/aqviews-mfWsMDdN-Ro-unsplash.jpg";
import BgKitchen from "src/assets/images/content-creators/pexels-kampus-production-7964648.jpg";
import BgDubbiiCam from "src/assets/images/content-creators/dubbii_picture_01.png";
import WhyGravitywellStartups from "src/components/startups-landing-page/WhyGravitywell";
import { MediumColumnWidthWrapper } from "src/components/confines-wrappers";
import Panel from "src/components/panel-wrapper/panel-wrapper";
import Testimonial from "src/components/content-creators-landing-page/Testimonials";
import Footer from "src/components/footer";
import GetInTouch from "src/components/get-in-touch";
import { CtaButton } from "src/components/cta";
import DubbiiCaseStudy from "src/components/content-creators-landing-page/dubbii-case-study";

const ContentCreatorsPage = (): JSX.Element => {
  const contactRef = useRef<HTMLDivElement | null>(null);
  const handleCTAClick = () => scrollSmoothly(contactRef);
  const canonicalPath = "/content-creators";

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Content creators",
      path: canonicalPath
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  return (
    <Layout className="layout">
      <HelmetWrapper
        title="Content creators"
        description="We help startups blossom and achieve their goals"
        canonicalPath={canonicalPath}
      >
        <script type="application/ld+json">
          {JSON.stringify(schemaJson)}
        </script>
      </HelmetWrapper>

      <Styled.header role="banner">
        <Header
          initialLogo
          initialTheme="dark"
        />

        <PageTopContentCreators />
      </Styled.header>

      <Styled.main>
        <DubbiiCaseStudy />

        {/* <CaseStudyPanel
          label="dubbii"
          heading="The Body Doubling app"
          linkCta="View Project"
          linkUrl="/projects/dubbii"
          backgroundImage={BgDubbiApp}
          desktopLayout="right"
          keyPoints={[ "Built for millions of ADHDers App Store Top 20 on launch weekend set to break 100,000 active users" ]}
        /> */}

        <CaseStudyPanel
          label=""
          heading="Who we work with"
          backgroundImage={BgCreator}
          desktopLayout="left"
          keyPoints={[
            "Bloggers",
            "YouTubers",
            "Streamers",
            "Influencers",
            "Podcasters",
            "Musicians",
            "Cosplayers",
            "Photographers"
          ]}
          linkCta="Let's talk"
          linkUrl="#get-in-touch"
        />

        <WhyGravitywellStartups
          text={[ "Our award-winning team of developers, designers and strategists have a wealth of experience in custom mobile app development for Content Creators and Influencers.", "We deploy our expertise to generate and validate your idea, then build and launch it to your audience — whether they’re on TikTok, Instagram, YouTube or elsewhere." ]}
        />

        <section id="revenue">
          <Panel
            backgroundImage={BgKitchen}
            style={{
              backgroundSize: "cover",
              position: "relative"
            }}
          >
            {null}
          </Panel>
        </section>

        <Testimonial
          backgroundColor="#6D7EEC"
          className="testi"
        />

      </Styled.main>

      <Styled.FloatingCTA>
        <CtaButton
          type="primary"
          href="#get-in-touch"
          text="Work with us"
          className="floating-cta"
        />
      </Styled.FloatingCTA>

      <footer
        role="contentinfo"
        ref={contactRef}
        style={{
          position: "relative",
          zIndex: 3
        }}
      >
        <GetInTouch
          title="Let's create something"
          slogan="Got a great idea?"
        />

        <Footer />
      </footer>
    </Layout>
  );
};

const Styled = {
  FloatingCTA: styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    margin: 2rem;
  `,
  header: styled.header`
		@media (max-width: 600px) {
			.top-content-container {
				min-height: 300px;
			}
			.section-cta-container {
				padding-top: 2.5rem;
			}
		}
	`,
  main: styled.main`
		&>*:nth-child(1) {
			.flex-wrapper {
				width: min(100%,340px);
			}
		}

		&>*:nth-child(2) {
			ul {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				max-height: none;
				row-gap: 1ch;
				column-gap: 6rem;
				align-content: flex-start;
				padding-inline: 0;
				
				@media (min-width: 600px) {
					max-height: 8lh;
				}
				@media (min-width: 900px) {
					max-height: 6lh;
				}
			}
			li::before {
				// content: "☑";
				content: "✓";
				font-size: 1.5em;
				margin-right: 0.5em;
			}
			li {
				list-style-type: none;
				padding-inline-start: 1ch;
				padding-block: 0em;
			}
			.section-cta-container {
				margin-inline: auto;
				text-align: center;
			}
		}

		section#revenue {
      position: relative;
      
			p {
        margin-top: auto;
				text-align: left;
				color: white;
				padding-left: 0%;
				font-size: 22px;
				filter: drop-shadow(0 0 1px #00000020) drop-shadow(0 0 5px #4f5a6390) drop-shadow(0 0 20px #4f5a6380) drop-shadow(0 0 50px #4f5a6340);
        
        @media (min-width: 640px) {
          font-size: 32px;
				}
        @media (min-width: 900px) {
          padding-left: 10%;
				}
			}

			& > :first-child {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				background-position: center 75%;

				@media (min-width: 1000px) {
					background-position: center 50%;
				}
				@media (min-width: 1500px) {
					background-position: center 35%;
				}

        &::before {
          content: '';
          background: linear-gradient(to top, #0005, #0000 50%);
          position: absolute;
          top: 0; left: 0; right: 0; bottom: 0;
        }
			}
		}

		section[id] {
			& > :first-child {
				min-height: 50rem;
				padding-top: 14% !important;
				padding-bottom: 14% !important;
			}
		
			@media (min-width: 480px) {
				& > :first-child {
					padding-top: 7.4rem !important;
					padding-bottom: 7.4rem !important;
				}
			}
		
			@media (min-width: 768px) {
				& > :first-child {
					padding-top: 8.8rem !important;
					padding-bottom: 8.8rem !important;
				}
			}
		}

    .testi * {
      color: white !important;
      fill: white !important;
    }
	`
};

const scrollSmoothly = (ref: React.MutableRefObject<HTMLDivElement|null>) => {
  const y_coord = ref?.current?.offsetTop ?? window.innerHeight;
  const padding = document.documentElement.querySelector("[class*='navigation-']")?.clientHeight ?? window.innerHeight / 6;

  window.scrollTo({
    top: y_coord - padding,
    behavior: "smooth"
  });
};

export default ContentCreatorsPage;